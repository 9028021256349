import { sdk as imageKitSDK } from '@wix/image-kit'
import { prefix as tpaPrefix } from '@wix/data-binding-tpa-actions'
import type { Action } from '@wix/data-binding-tpa-actions'
import type { RecordStoreRecord } from '../record-store/service'
import type { DynamicItemPageSeo } from '../seo/types'
import type { ROUTER_DATASET } from '@wix/wix-data-client-common'
import { DATASET } from '@wix/wix-data-client-common'
import type datasetEntity from '../dataset/dataset-entity'
import type { DatasetSort } from '@wix/wix-data-client-common-standalone'
import type { Connection } from '../types'
import type { FilterTree, Sort } from '../contract'
import type { Features } from './Features'

interface SeoApi {
  renderSEOTags: (payload: DynamicItemPageSeo) => Promise<void>
}

interface WixSdk {
  location: {
    readonly pageId: string
    readonly url: string
    readonly baseUrl: string
    readonly path: string[]
    readonly query: {
      [param: string]: string
    }
    readonly to: (url: string) => void
    readonly onChange: (callback: () => void) => void
  }
  user: any
  environment: any
  window: any
  site: any
  seo: SeoApi
}

interface PlatformUtils {
  links: any
  mediaItemUtils: any
}

interface AppData {
  gridAppId: string
  veloCodeIsPresentOnCurrentPage: boolean
}

type Bi = any

type TpaActionImplementation = (params: {
  currentItem: RecordStoreRecord
  wixSdk: any
}) => void

export class Platform {
  constructor({
    platformUtils,
    wixSdk,
    bi,
    tpaActionImplementations,
    devMode,
    verbose,
  }: {
    platformUtils: PlatformUtils
    wixSdk: WixSdk
    bi: Bi
    tpaActionImplementations: Record<Action, TpaActionImplementation>
    devMode: boolean
    verbose: boolean
  }) {
    this.#postMessage = (message: any) => wixSdk.window.postMessage(message)
    // you need to note, that some of those params are not static during app's lifecycle
    // some are static nowm but may change in the future.
    // for non-static props see the `#user` implementation
    this.#settings = this._getSettings({ wixSdk, bi, devMode, verbose })
    this.#user = this._getUser(wixSdk.user)
    this.#location = this._getLocation(wixSdk.location, bi)
    this.#utils = this._getUtils(platformUtils)
    this.#timers = this._getTimers(wixSdk)

    this.#executeTpaAction = async ({
      currentItem,
      action,
    }: {
      currentItem: RecordStoreRecord
      action: Action
    }) => {
      const implementation = tpaActionImplementations[action]
      await implementation({ wixSdk, currentItem })
    }
    this.#seo = this._getSeo(wixSdk)
  }

  get postMessage() {
    return this.#postMessage
  }

  get settings() {
    return this.#settings
  }

  get user() {
    return this.#user
  }

  get location() {
    return this.#location
  }

  get utils() {
    return this.#utils
  }

  get timers() {
    return this.#timers
  }

  get executeTpaAction() {
    return this.#executeTpaAction
  }

  get seo() {
    return this.#seo
  }

  #settings
  #user
  #location
  #utils
  #timers
  #executeTpaAction
  #seo
  #postMessage

  _getSettings({
    wixSdk: {
      window: {
        viewMode,
        rendering: { env },
        browserLocale,
      },
      site: { regionalSettings = browserLocale },
    },
    bi: { metaSiteId, viewerName },
    devMode,
    verbose,
  }: {
    wixSdk: WixSdk
    bi: Bi
    devMode: boolean
    verbose: boolean
  }) {
    return {
      metaSiteId,
      locale: regionalSettings,
      mode: {
        name: env,
        dev: devMode,
        verbose,
        ssr: env === 'backend',
        csr: env !== 'backend',
      },
      env: {
        name: viewMode,
        live: viewMode === 'Site',
        preview: viewMode === 'Preview',
        livePreview: viewMode === 'Editor',
        editor: viewMode === 'Preview' || viewMode === 'Editor',
        renderer: viewerName,
      },
    }
  }

  _getUser(user: WixSdk['user']) {
    return {
      get id() {
        return user.currentUser.id
      },
      get loggedIn() {
        return user.currentUser.loggedIn
      },
      onLogin: user.onLogin,
    }
  }

  _getLocation(wixLocation: WixSdk['location'], { pageId }: Bi) {
    return {
      pageId,
      get pageUrl() {
        return wixLocation.url
      },
      get baseUrl() {
        return wixLocation.baseUrl
      },
      get path() {
        return wixLocation.path
      },
      queryParams: wixLocation.query,
      navigateTo: wixLocation.to,
      onChange: wixLocation.onChange,
    }
  }

  _getUtils({ links, mediaItemUtils }: PlatformUtils) {
    return {
      links,
      media: {
        ...mediaItemUtils,
        getScaleToFillImageURL: imageKitSDK.getScaleToFillImageURL,
      },
    }
  }

  _getTimers(wixSdk: WixSdk) {
    return {
      queueMicrotask:
        wixSdk.environment?.timers?.queueMicrotask || queueMicrotask,
    }
  }

  _getSeo(wixSdk: WixSdk) {
    return {
      renderSEOTags: wixSdk.seo.renderSEOTags,
    }
  }
}

export type ControllerConfig = {
  compId: string
  type: typeof DATASET | typeof ROUTER_DATASET
  config: {
    dataset?: {
      collectionName: string | null
      filter: FilterTree | null
      sort: Sort | null
      includes: string[] | null
      nested: string[]
      pageSize: number
      readWriteType: 'READ' | 'WRITE' | 'READ_WRITE'
      deferred?: boolean
      cursor?: boolean
    }
  }
  connections: Connection[]
  livePreviewOptions?: {
    shouldFetchData?: boolean
    compsIdsToReset?: string[]
  }
}

export type RouterConfig = {
  config: {
    dataset: {
      collectionName: string
      filter: FilterTree | null
      sort: DatasetSort | null
      includes: string[] | null
      pageSize: number
      seoV2: boolean
    }
  }
  dynamicUrl: string
  userDefinedFilter: FilterTree
}

export const readWriteTypeMap = {
  READ: 'read' as const,
  WRITE: 'write' as const,
  READ_WRITE: 'read-write' as const,
}

const getUniqueFieldIdsFromConnections = (connections: Connection[]) => {
  const fieldIds = new Set<string>()
  for (const { config } of connections) {
    const stack = [config]

    while (stack.length) {
      const obj = stack.pop()
      if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
        continue
      }
      for (const [key, value] of Object.entries(obj)) {
        if (key === 'expressions') {
          return undefined
        }
        if (value && typeof value === 'string') {
          if (key === 'action' && value.startsWith(tpaPrefix)) {
            return undefined
          }
          if (key === 'fieldName') {
            fieldIds.add(value.split('.')[0])
            continue
          }
        }
        stack.push(value)
      }
    }
  }

  return fieldIds.size ? [...fieldIds] : ['_id']
}

export const controllerConfigToDatasetConverter =
  ({
    makeDataset,
    features,
    appData,
  }: {
    makeDataset: typeof datasetEntity.make
    features: Features
    appData: AppData
  }) =>
  (controllerConfigs: ControllerConfig[], routerData?: RouterConfig) =>
    controllerConfigs.map(
      ({
        compId,
        type,
        connections,
        config: { dataset = {} },
        livePreviewOptions: { shouldFetchData, compsIdsToReset } = {},
      }) => {
        const { readWriteType, deferred, cursor, nested } = dataset
        const { collectionName, pageSize, filter, sort, includes } =
          type === DATASET ? dataset : routerData?.config.dataset ?? {}

        return makeDataset({
          config: {
            id: compId,
            type: type === DATASET ? 'regular' : 'router',
            collectionId: collectionName,
            readWriteType: readWriteType
              ? readWriteTypeMap[readWriteType]
              : undefined,
            deferred,
            pagination: {
              pageSize,
              type: cursor ? 'cursor' : 'offset',
            },
            rendering: {
              connections,
              nestedFieldIds: nested,
              componentIdsToRender: compsIdsToReset,
            },
            dataLoading: {
              filter,
              sort,
              fieldIdsToFetch:
                features.fetchOnlyConnectedFields &&
                !appData.veloCodeIsPresentOnCurrentPage
                  ? getUniqueFieldIdsFromConnections(connections)
                  : undefined,
              referenceFieldIdsToFetch: includes ?? undefined,
              dataIsInvalidated: shouldFetchData,
            },
            routerConfig: routerData
              ? {
                  dynamicUrl: routerData.dynamicUrl,
                  userDefinedFilter: routerData.userDefinedFilter,
                  seoEnabled: routerData.config.dataset.seoV2,
                }
              : null,
          },
        })
      },
    )
